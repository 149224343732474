import api from "@/config/api";
import {
  ActionType,
  BookingRequest,
  RequestStatus,
  ActionSource,
} from "@/types/booking-request";
import { getSession } from "next-auth/react";

// Add types for lookups
interface Lookups {
  actionTypes: ActionType[];
  requestStatuses: RequestStatus[];
  actionSources: ActionSource[];
}

// New unified method to get all lookups
export const getLookups = async (): Promise<Lookups> => {
  try {
    const session = await getSession();
    if (!session?.accessToken) {
      throw new Error("Authentication required");
    }

    const response = await api.get("/lookups", {
      headers: {
        Authorization: `Bearer ${session.accessToken}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching lookups:", error);
    throw error;
  }
};

// Keep individual methods if needed, but implement them using the cached data
// or individual endpoints
export const getRequestStatuses = async () => {
  try {
    const session = await getSession();
    if (!session?.accessToken) {
      throw new Error("Authentication required");
    }

    const response = await api.get("/lookups/request-statuses", {
      headers: {
        Authorization: `Bearer ${session.accessToken}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching request statuses:", error);
    throw error;
  }
};

export const getActionTypes = async () => {
  try {
    const session = await getSession();
    if (!session?.accessToken) {
      throw new Error("Authentication required");
    }

    const response = await api.get("/lookups/action-types", {
      headers: {
        Authorization: `Bearer ${session.accessToken}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching action types:", error);
    throw error;
  }
};

export const getActionSources = async () => {
  try {
    const session = await getSession();
    if (!session?.accessToken) {
      throw new Error("Authentication required");
    }

    const response = await api.get("/lookups/action-sources", {
      headers: {
        Authorization: `Bearer ${session.accessToken}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching action sources:", error);
    throw error;
  }
};

export const createBookingRequest = async (data: BookingRequest) => {
  try {
    const session = await getSession();
    if (!session?.accessToken) {
      throw new Error("Authentication required");
    }
    const response = await api.post("/booking-requests", data, {
      headers: {
        Authorization: `Bearer ${session.accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error creating booking request:", error);
    throw error;
  }
};

export const updateBookingRequest = async (
  id: string,
  data: BookingRequest
) => {
  try {
    const session = await getSession();
    if (!session?.accessToken) {
      throw new Error("Authentication required");
    }
    const response = await api.patch(`/booking-requests/${id}`, data, {
      headers: {
        Authorization: `Bearer ${session.accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error updating booking request:", error);
    throw error;
  }
};

export const getBookingRequests = async (
  params: {
    page?: number;
    limit?: number;
    search?: string;
    status?: string;
  } = {}
): Promise<{
  data: BookingRequest[];
  total: number;
  page: number;
  limit: number;
}> => {
  try {
    const session = await getSession();
    if (!session?.accessToken) {
      throw new Error("Authentication required");
    }

    const response = await api.get("/booking-requests", {
      headers: {
        Authorization: `Bearer ${session.accessToken}`,
      },
      params: {
        ...params,
      },
    });

    return {
      data: response.data.data || [],
      total: response.data.total || 0,
      page: response.data.page || 1,
      limit: response.data.limit || 10,
    };
  } catch (error) {
    console.error("Error fetching booking requests:", error);
    throw error;
  }
};

export const getBookingRequest = async (id: string) => {
  try {
    const session = await getSession();
    if (!session?.accessToken) {
      throw new Error("Authentication required");
    }

    const response = await api.get(`/booking-requests/${id}`, {
      params: {
        include: "user",
      },
      headers: {
        Authorization: `Bearer ${session.accessToken}`,
      },
    });

    if (!response.data || !response.data.id) {
      console.warn(`Booking request with ID ${id} not found.`);
      return null;
    }

    return response.data;
  } catch (error) {
    console.error("Error fetching booking request:", error);
    throw error;
  }
};

export const sendBookingRequestEmail = async (bookingRequestId: number) => {
  try {
    const session = await getSession();
    if (!session?.accessToken) {
      throw new Error("Authentication required");
    }

    const response = await api.post(
      `/booking-requests/${bookingRequestId}/send-email`,
      {},
      {
        headers: {
          Authorization: `Bearer ${session.accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.data) {
      throw new Error("No response received from email service");
    }

    return response.data;
  } catch (error: any) {
    console.error("Error sending booking request email:", error);
    if (error.response?.status === 400) {
      throw new Error(
        `Failed to send email: ${error.response.data?.message || "Bad Request"}`
      );
    }
    throw error;
  }
};
