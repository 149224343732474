"use client";

import { useEffect } from "react";
import { H } from "@highlight-run/next/client";

// Define strict types for metadata
type SafeValue = string | number | boolean;
type SafeMetadata = Record<string, SafeValue>;

// Utility function to safely convert values
const toSafeValue = (value: unknown): SafeValue => {
  if (typeof value === 'string') return value;
  if (typeof value === 'number') return value;
  if (typeof value === 'boolean') return value;
  if (value === null || value === undefined) return '';
  if (typeof value === 'object') {
    try {
      return JSON.stringify(value);
    } catch {
      return '[Object]';
    }
  }
  return String(value);
};

// Convert data to safe metadata
const toSafeMetadata = (data?: Record<string, unknown>): SafeMetadata => {
  if (!data) return {};
  return Object.entries(data).reduce<SafeMetadata>((acc, [key, value]) => {
    acc[key] = toSafeValue(value);
    return acc;
  }, {});
};

// Debounce with proper types
function debounce<T extends (...args: any[]) => any>(
  fn: T,
  ms = 1000
): (...args: Parameters<T>) => void {
  let timeoutId: ReturnType<typeof setTimeout>;
  return (...args: Parameters<T>) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn(...args), ms);
  };
}

export function MonitoringProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  useEffect(() => {
    if (typeof window === "undefined" || process.env.NODE_ENV !== "production") {
      return;
    }

    try {
      // Global error handler
      window.onerror = (message, source, lineno, colno, error) => {
        const errorData: SafeMetadata = {
          source: String(source || ''),
          lineno: Number(lineno) || 0,
          colno: Number(colno) || 0,
          react: true,
          timestamp: new Date().toISOString(),
        };

        try {
          if (error instanceof Error) {
            H.track("ErrorDetailed", {
              ...errorData,
              name: error.name || 'Error',
              message: error.message || 'Unknown error',
              stack: error.stack || '',
            });
            H.consumeError(error);
          } else {
            const errorMessage = String(message || 'Unknown error');
            const errorObj = new Error(errorMessage);
            H.track("ErrorString", {
              ...errorData,
              message: errorMessage,
            });
            H.consumeError(errorObj);
          }
        } catch (trackError) {
          console.error("Error tracking failed:", trackError);
        }
        return false;
      };

      // User identification
      const userStr = localStorage.getItem("user");
      if (userStr) {
        try {
          const user = JSON.parse(userStr);
          if (user?.email) {
            H.identify(user.email, {
              name: String(user.name || ''),
              email: String(user.email),
              role: String(user.roles?.[0]?.role?.name || 'unknown'),
            });
          }
        } catch (error) {
          console.error("User identification failed:", error);
        }
      }
    } catch (error) {
      console.error("Monitoring initialization failed:", error);
    }
  }, []);

  return children;
}

// Logger implementation
export const logger = {
  info: debounce((message: string, data?: Record<string, unknown>) => {
    if (process.env.NODE_ENV === "production") {
      try {
        H.track(message, toSafeMetadata(data));
      } catch (error) {
        console.error("Info logging failed:", error);
      }
    }
  }, 1000),

  error: debounce((error: unknown, data?: Record<string, unknown>) => {
    if (process.env.NODE_ENV !== "production") {
      console.error(error);
      return;
    }

    try {
      const errorObj = error instanceof Error ? error : new Error(
        typeof error === 'string' ? error : 'Unknown error'
      );

      const metadata: SafeMetadata = {
        message: errorObj.message,
        stack: errorObj.stack || '',
        type: error instanceof Error ? error.constructor.name : typeof error,
        timestamp: new Date().toISOString(),
        ...toSafeMetadata(data)
      };

      H.track("Error", metadata);
      H.consumeError(errorObj);
    } catch (handlingError) {
      console.error("Error logging failed:", handlingError);
      try {
        H.track("CriticalError", {
          message: "Error handler failed",
          originalError: String(error),
          handlingError: String(handlingError),
        });
      } catch {} // Silently fail if all tracking attempts fail
    }
  }, 1000),
};
