"use client";

import React from "react";
import { logger } from "@/services/monitoring";
import { H } from "@highlight-run/next/client";

interface ErrorBoundaryState {
  hasError: boolean;
  error?: Error;
}

class ErrorBoundary extends React.Component<
  { children: React.ReactNode },
  ErrorBoundaryState
> {
  constructor(props: { children: React.ReactNode }) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidMount() {
    if (typeof window === 'undefined') return;

    // Add error listeners
    window.addEventListener("unhandledrejection", this.handleUnhandledRejection);
    window.addEventListener("error", this.handleError);

    // Track mount
    H.track("ErrorBoundary_Mount", {
      pathname: window.location.pathname,
      timestamp: new Date().toISOString()
    });
  }

  componentWillUnmount() {
    if (typeof window === 'undefined') return;
    
    window.removeEventListener("unhandledrejection", this.handleUnhandledRejection);
    window.removeEventListener("error", this.handleError);
  }

  private handleUnhandledRejection = (event: PromiseRejectionEvent) => {
    const error = event.reason instanceof Error ? event.reason : new Error(String(event.reason));
    logger.error(error, { type: "UnhandledRejection" });
    this.setState({ hasError: true, error });
  };

  private handleError = (event: ErrorEvent) => {
    const error = event.error || new Error(event.message);
    logger.error(error, { type: "GlobalError" });
    this.setState({ hasError: true, error });
  };

  public static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { hasError: true, error };
  }

  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    logger.error(error, {
      type: "ComponentError",
      componentStack: errorInfo.componentStack,
    });
  }

  private handleReset = () => {
    this.setState({ hasError: false, error: undefined });
    window.location.reload();
  };

  public render() {
    if (this.state.hasError) {
      return (
        <div className="flex flex-col items-center justify-center min-h-screen p-4">
          <h2 className="text-2xl font-bold mb-4">Something went wrong</h2>
          <button
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            onClick={this.handleReset}
          >
            Try again
          </button>
        </div>
      );
    }

    return this.props.children || <div />; // Provide fallback for null children
  }
}

export default ErrorBoundary;
