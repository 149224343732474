import(/* webpackMode: "eager" */ "/var/www/apps/dashboard/public/logo-short.svg");
;
import(/* webpackMode: "eager" */ "/var/www/apps/dashboard/public/logo.svg");
;
import(/* webpackMode: "eager" */ "/var/www/apps/dashboard/src/app/[lang]/(hydrogen)/ErrorBoundary.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/apps/dashboard/src/app/[lang]/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["ClientProviders"] */ "/var/www/apps/dashboard/src/providers/client-providers.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/apps/dashboard/src/services/monitoring.ts");
;
import(/* webpackMode: "eager", webpackExports: ["HighlightInit"] */ "/var/www/node_modules/.pnpm/@highlight-run+next@7.6.7_@opentelemetry+api@1.9.0_next@14.2.3_@babel+core@7.26.0_@openteleme_in4kmq7nz67dr5tnltoplqtmha/node_modules/@highlight-run/next/dist/next-client.js");
;
import(/* webpackMode: "eager" */ "/var/www/node_modules/.pnpm/next@14.2.3_@babel+core@7.26.0_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.63.6/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/var/www/node_modules/.pnpm/next@14.2.3_@babel+core@7.26.0_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.63.6/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/var/www/node_modules/.pnpm/next@14.2.3_@babel+core@7.26.0_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.63.6/node_modules/next/font/google/target.css?{\"path\":\"src/app/fonts.ts\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-inter\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/var/www/node_modules/.pnpm/next@14.2.3_@babel+core@7.26.0_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.63.6/node_modules/next/font/google/target.css?{\"path\":\"src/app/fonts.ts\",\"import\":\"Lexend_Deca\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-lexend\"}],\"variableName\":\"lexendDeca\"}");
;
import(/* webpackMode: "eager" */ "/var/www/node_modules/.pnpm/next@14.2.3_@babel+core@7.26.0_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.63.6/node_modules/next/font/google/target.css?{\"path\":\"src/app/fonts.ts\",\"import\":\"Cairo\",\"arguments\":[{\"subsets\":[\"arabic\"],\"variable\":\"--font-cairo\"}],\"variableName\":\"cairo\"}");
