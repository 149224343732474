"use client";

import { createContext, useContext, ReactNode } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getLookups } from '@/app/api/booking-requests';
import type { ActionType, RequestStatus, ActionSource } from '@/types/booking-request';

interface LookupsContextType {
  actionTypes: ActionType[];
  requestStatuses: RequestStatus[];
  actionSources: ActionSource[];
  isLoading: boolean;
  error: Error | null;
}

const LookupsContext = createContext<LookupsContextType>({
  actionTypes: [],
  requestStatuses: [],
  actionSources: [],
  isLoading: true,
  error: null,
});

export function LookupsProvider({ children }: { children: ReactNode }) {
  const { data, isLoading, error } = useQuery({
    queryKey: ['lookups'],
    queryFn: getLookups,
    staleTime: 1000 * 60 * 60 * 24, // 24 hours
    gcTime: 1000 * 60 * 60 * 24,
  });

  const value = {
    actionTypes: data?.actionTypes ?? [],
    requestStatuses: data?.requestStatuses ?? [],
    actionSources: data?.actionSources ?? [],
    isLoading,
    error: error as Error | null,
  };

  return (
    <LookupsContext.Provider value={value}>
      {children}
    </LookupsContext.Provider>
  );
}

export const useLookups = () => {
  const context = useContext(LookupsContext);
  if (context === undefined) {
    throw new Error('useLookups must be used within a LookupsProvider');
  }
  return context;
}; 